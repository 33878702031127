import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home, { HomeMobile } from "./pages/Home";
import AIBoxCaseStudy from "./pages/AIBoxCaseStudy";
import Project from "./pages/Project";
import { useMediaQuery } from "@uidotdev/usehooks";
import ProjectsData from "./projects.json";
import { ProjectInfo } from "./components/Projects";
// redux
import { useDispatch } from "react-redux";
import { setProjects } from "./redux/projects/projectsSlice";

function App() {
	const desktop = useMediaQuery("only screen and (min-width : 993px)");
	const dispatch = useDispatch();

	const projects = ProjectsData.projects as ProjectInfo[];
	dispatch(setProjects(projects));

	return (
		<BrowserRouter>
			<div>
				<div>
					<Routes>
						<Route
							path="/"
							element={desktop ? <Home /> : <HomeMobile />}
						/>
						{projects.map((project, index) => (
							<Route
								key={index}
								path={project.path}
								element={<Project projectInfo={project} />}
							/>
						))}
						<Route
							path="/AIBoxCaseStudy"
							element={<AIBoxCaseStudy />}
						/>
					</Routes>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default App;
