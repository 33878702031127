import { useEffect } from "react";

const json = {
	steps: [
		{
			title: "Title",
			subtitle: "Subtitle",
			substeps: [
				{
					title: "Title",
					subtitle: "Subtitle",
					type: "text",
					key: "zustand get key",
					onsubmitkey: "zustand update key",
				}
			]
		}
	]
};

const comp = `interface InputComponentProps {
	title: string,
	subtitle: string,
	key: string,
	onsubmitkey: string,
}

// type omitted here because it would be used to pick which component is being used
const InputComponent = ({title, subtitle, key, onsubmitkey}: InputComponentProps) => {
	const val = useStore(state => state[key]);
	const changeValFn = useStore(state => state[onsubmitkey]);

	// normal react Fn
	// ...
}
`


const AIBoxCaseStudy = () => {
	useEffect(() => {
		document.title = "ML | AI Box Case Study";
	});

	return (
		<div className="w-[40%] mx-auto mb-2 mt-12">
			<h1 className="text-white text-xl mb-6 font-bold">Multi-Step Forms: A Case Study</h1>

			<h2 className="text-white text-lg my-4">Introduction</h2>
			<p className="my-4">AI Box was building an application in which creators would use a no-code editor to construct complicated prompts that aggregate the results from LLMs of choice. Creators would publish and list these applications on a marketplace where users (and other creators) could spend tokens to generate content with their prompt applications. The final step between the no-code editor and getting the application to market was what we referred to as the "listing creation" process. This was where I came in.</p>

			<h2 className="text-white text-lg my-4">Listing Creation</h2>
			<p className="my-4">At the time I was tasked with developing the listing creation process, there were many things about the individual listings that weren't finalized; it was unclear what kind of metadata we were going to collect about the listings, what sort of visual content creators would be allowed to attach (cover photo, icon photo, etc.), and what kind of tag system we planned to use. We did know, however, that we wanted to implement a multi-step form that creators would click through as they prepared to publish their listing.</p>

			<h2 className="text-white text-lg my-4">Why</h2>
			<p className="my-4">Before I get into the details of my implementation, it would be worth devoting a second to explaining why this feature was so important. Standing as the final step before an application goes public, the listing creation process was where creators do everything they can to make their application look appealing to users on the marketplace. During this phase, creators would choose cover and icon photos, write a description, and assign tags in order to draw users in. Finally, from the perspective of the entire site-tree, this step had broad implications for many other pages, from the listing page itself, to the marketplace where users browsed custom applications. If any of these designs changed, the listing creation step would need to change as well.</p>

			<h2 className="text-white text-lg my-4">How</h2>
			<p className="my-4">Given how essential it was to be able to add and remove steps to this multi step form, I knew that a highly modular solution would be best. Furthermore, it was clear that many of these steps had broad structural similarities; users would be entering text, or uploading a photo, or selecting from a dropdown, to name a few interactions. With these two factors in mind, I set out to develop a JSON object which could describe all of the information required to create a single step of the multi-step form. Using this format, steps could be added and removed at-will from an array, which a React component would then iterate over and process into fully functional UI components composed of pre-made form input components for text, file, dropdown, and multi-select input. The JSON format looked like this:</p>

			<pre className="text-white px-4 border-l-2 border-slate-800">{JSON.stringify(json, null, 2)}</pre>

			<p className="my-4">Several things are worth noting here. First, the distinction between the array of steps and the array of substeps is significant; each step represents a complete view or page that the user sees when they are filling out the form, while each substep corresponds to an individual input component for the user to fill out. The title and subtitle for each step operate more on the level of information categories like "personal information," "media," etc.</p>

			<h2 className="text-white text-lg my-4">Introducing Zustand</h2>
			<p className="my-4">Other than the step/substep distinction, the only JSON values in this format that need clarifying are "key" and "onsubmitkey." Since the core application involved a data-heavy node-based editor, there was an enormous amount of local state to manage. To help balance the load and navigate the labrythine map of data connections and dependencies, we used <a className="underline text-[#dddddd]" href="https://github.com/pmndrs/zustand" target="_blank">zustand</a>, a simple yet powerful state management library. For my modular listing-creation, the immediate question became how to reference the zustand store, and the function which had the permission to update the store, using JSON values.</p>

			<h2 className="text-white text-lg my-4">A Saving Grace</h2>
			<p className="my-4">I was surprised and pleased to learn that zustand not only stores state values as a key/value pair in a dictionary, it does the same for reference to state-changing functions as well! And since the keys in both cases are normal JavaScript strings, they could be used without trouble in our JSON object. This enabled the existence of the "key" and "onsubmitkey" values, which allowed me to specify what local state values will be read from and written to for each substep generated from the JSON document. At that point, the only thing left was to map the types to different input components, and compose the components into a full page with the relevant state information.</p>

			<pre className="text-white border-l-2 pl-4">{comp}</pre>
		</div>
	);
}

export default AIBoxCaseStudy;
