import { useState } from "react";

const Bio = () => {
	return (
		<section id="bio" className=" mt-[8em] mb-16">
			<div className=" justify-between">
				<img src="apple-touch-icon.png" className="w-8 pb-8"></img>
				<p className="text-white pb-4">
					Mattias Lambert — Software Engineer + Game Dev
				</p>
			</div>

			<p className="pb-2">
				Most recently I was at{" "}
				<a
					className="underline hover:no-underline"
					href="https://aibox.ai/"
				>
					AI Box
				</a>{" "}
				building an AI tool marketplace.
			</p>
			<p className="pb-2">
				Before that I was Lead Game Developer at{" "}
				<a
					className=" underline hover:no-underline"
					href="https://www.gameexpro.com/"
					target="_blank"
					rel="noreferrer"
				>
					GameExPro
				</a>
				, where I worked on VR escape room video games.
			</p>
			<p className="pb-[2rem]">
				{" "}
				In my free time, I like to teach myself new programming languages, play chess, and ride my bike.
			</p>

			<p className="w-fit my-0 py-2 px-[10px] text-white bg-white bg-opacity-15 rounded-md mr-[.75em]  hover:bg-opacity-30  hover:cursor-pointer">
				<a
					className="flex items-center text-neutral-400"
					href="mailto:mattiasl380@gmail.com"
				>
					<div className="animate-ping rounded-full bg-green-400 h-[8px] w-[8px] inline-block mr-2 absolute"></div>
					<div className="rounded-full bg-green-400 h-[8px] w-[8px] inline-block mr-2"></div>
					Currently open to work. Please get in touch!
				</a>
				{/* <a href="mailto:mattiasl380@gmail.com">Contact</a> */}
			</p>
		</section>
	);
};

export const BioMobile = () => {
	const [open, setOpen] = useState(0);

	const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

	const bioOpenStyle = "text-black py-1 mb-4 border-none";
	const bioClosedStyle = "text-black py-1 mb-4 border-none underline";

	return (
		<section id="bio" className=" mt-[4em] mb-16">
			<div className=" justify-between">
				<p className="font-bold">Mattias Lambert</p>
				<p>Game Dev + Software Engineer</p>

				{/* <ul id="links" className="flex mt-2">
                    <li className="px-[12px] py-[4px] text-white bg-white bg-opacity-15 rounded-md mr-[.75em] hover:bg-opacity-30 ">
                        <a
                            href="resume.pdf"
                            download="MattiasLambertResume.pdf"
                        >
                            Resume
                        </a>
                    </li>
                    <li className="px-[12px] py-[4px] text-white bg-white bg-opacity-15 rounded-md mr-[.75em] hover:bg-opacity-30 ">
                        <a
                            href="https://www.linkedin.com/in/mattias-lambert-09a425280"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Linkedin
                        </a>
                    </li>
                    <li className="px-[12px] py-[4px] text-white bg-white bg-opacity-15 rounded-md mr-[.75em]  hover:bg-opacity-30 ">
                        <a href="mailto:mattiasl380@gmail.com"> Contact</a>
                    </li>
                </ul> */}
			</div>

			<p className="py-4 ">
				Passionate about puzzle games and elegant websites. Experienced
				in Unity, Unreal, and bevy. I use React and Tailwind for web
				dev, and I've been experimenting with Astro and htmx.
			</p>
			<p className="pb-6">
				Previously Lead Developer at{" "}
				<a
					className=" underline hover:no-underline"
					href="https://www.gameexpro.com/"
					target="_blank"
					rel="noreferrer"
				>
					GameExPro
				</a>
				.
			</p>
			<p className="w-fit py-[4px] px-[10px] text-white bg-white bg-opacity-15 rounded-md mr-[.75em]  hover:bg-opacity-30  hover:cursor-pointer">
				<a href="mailto:mattiasl380@gmail.com">
					Currently available for work.
				</a>
			</p>
		</section>
	);
};

export default Bio;
