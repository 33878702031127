import { useEffect, useState } from "react";
import Bio, { BioMobile } from "../components/Bio";
import Projects, { ProjectsMobile } from "../components/Projects";

const Home = () => {
	const [filter, setFilter] = useState("");

	useEffect(() => {
		document.title = "Mattias Lambert";
	}, []);

	return (
		<div className="w-[32%] mx-auto my-[2em] ">
			<Bio />
			<Projects filter={filter} />
			<div>
				<ul className="flex justify-between mb-[4em]">
					<li>
						<a href="mailto:mattiasl380@gmail.com">email</a>
					</li>
					<div className="flex">
						<li className="mr-4">
							<a
								href="MattiasLambertResume.pdf"
								download="MattiasLambertResume.pdf"
							>
								resume
							</a>
						</li>
						<li>
							<a
								href="www.linkedin.com/in/mattias-lambert-09a425280"
								target="_blank"
								rel="noreferrer"
							>
								linkedin
							</a>
						</li>
					</div>
				</ul>
			</div>
		</div>
	);
};

export const HomeMobile = () => {
	useEffect(() => {
		document.title = "Mattias Lambert";
	});

	return (
		<div className="w-[80%] block mx-auto ">
			{/* <p className="text-3xl font-bold mt-[10%]">Mattias Lambert</p> */}
			<BioMobile />
			<ProjectsMobile />
			<div>
				<ul className="flex mb-[4em]">
					<li className="mr-4">
						<a href="mailto:mattiasl380@gmail.com">email</a>
					</li>
					<li className="mr-4">
						<a
							href="resume.pdf"
							download="MattiasLambertResume.pdf"
						>
							resume
						</a>
					</li>
					<li>
						<a
							href="www.linkedin.com/in/mattias-lambert-09a425280"
							target="_blank"
							rel="noreferrer"
						>
							linkedin
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Home;
